import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import AppRoutes from './Routes/AppRoutes';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <Router>
      <AppRoutes/>
      <Toaster />

    </Router>
    
  );
}

export default App;
