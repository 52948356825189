import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  
  token: "",
  
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setToken: (state, action) => ({
      ...state,
      token: action.payload,
    }),
    
    
  },
});

// Action creators are generated for each case reducer function
export const { setToken} = globalSlice.actions;

export default globalSlice.reducer;
