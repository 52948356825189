import { createApi } from "@reduxjs/toolkit/query/react";
import {baseQueryWithEncryption} from "../BaseQuery"

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQueryWithEncryption,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => {
        return {
          url: `/auth/login`,
          method: "POST",
          body: data,
        };
      },
    }),
    forgotPassword: builder.mutation({
      query: (data) => {
        return {
          url: `/auth/forgot-password`,
          method: "PUT",
          body: data,
        };
      },
    }),
    resendEmailOtp: builder.mutation({
      query: (data) => {
        return {
          url: `/auth/sendEmailOtp`,
          method: "POST",
          body: data,
        };
      },


    }),
    getUserDetails: builder.query({
      query: (filters) => {
        return {
          url: `/admin/get-users`,
          method: "GET",
          params: {
            ...(filters.acc_freeze && { acc_freeze: filters.acc_freeze }),
            ...(filters.kyc_verified && { kyc_verified: filters.kyc_verified }),
            ...(filters.emailOrPhoneNumber && { emailOrPhoneNumber: filters.emailOrPhoneNumber })
          },
        };
      }
    }),
    kycDetails: builder.mutation({
      query: (data) => {
        return {
          url: `/settings/tog-kyc`,
          method: "PUT",
          body: data,
        };
      }
    }),
    getKycDetails: builder.query({
      query: () => {
        return {
          url: `/settings/get-kyc`,
          method: "GET",
        };
      }
    }),
    resetOldPassword: builder.mutation({
      query: (data) => {
        return {
          url: `/admin/reset-password`,
          method: "POST",
          body: data,
        };
      }
    }),
    verifyLogin: builder.mutation({
      query: (data) => {
        return {
          url: `/auth/login/verify`,
          method: "POST",
          body: data,
        };
      }
    }),
    getTradeFee: builder.query({
      query: () => {
        return {
          url: `/admin/get-trade-fee`,
          method: "GET",
          params: {
            instType: "SPOT"
          }
        };
      }
    }),
    setTradeFee: builder.mutation({
      query: (data) => {
        return {
          url: `/admin/set-trade-fee`,
          method: "POST",
          body: data,
        };
      }
    }),

  })
});
export const { useLoginMutation, useVerifyLoginMutation, useForgotPasswordMutation, useResendEmailOtpMutation, useGetUserDetailsQuery,
  useKycDetailsMutation, useGetKycDetailsQuery, useResetOldPasswordMutation,
  useGetTradeFeeQuery, useSetTradeFeeMutation
} = authApi;

