import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { config } from './Config';
import CryptoJS from 'crypto-js';

export const rtkBaseQuery = fetchBaseQuery({
  baseUrl: config.BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = JSON.parse(localStorage.getItem('token'));
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const baseQueryWithEncryption = async (args, api, extraOptions) => {
  if (args.body) {
    const encryptedBody = CryptoJS.AES.encrypt(
      JSON.stringify(args.body),
      config.AES_ENC_KEY
    );

    args.body = { data: encryptedBody.toString() };
  }

  return rtkBaseQuery(args, api, extraOptions);
};
