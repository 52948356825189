import { isJwtExpired } from "jwt-check-expiration";
export const capitalizeWord = (word) => {
    const wordsArr = word?.split(" ");
  
    return wordsArr
      ?.map(
        (item) => item?.slice(0, 1)?.toUpperCase() + item?.slice(1)?.toLowerCase()
      )
      ?.join(" ");
  };

  export const checkIfLogin = () => {
    const token = JSON.parse(localStorage.getItem("token"));
    return !!token && !isJwtExpired(token);
  };
  