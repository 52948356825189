import { Spin } from "antd";
import { StyledLoader } from "./CustomLoader.styles";

const CustomLoader = ({ tip }) => {
    return (
      <StyledLoader>
        <Spin size="large" tip={tip} />
      </StyledLoader>
    );
  };

  export default CustomLoader;