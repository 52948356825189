import React from 'react'
import { Suspense, lazy } from "react";
import { Route, Routes } from 'react-router';
import CustomLoader from '../Components/CustomLoader/CustomLoader';
import { ROUTES } from '../Constants/Routes';
import PublicRoute from './PublicRoutes';
import PrivateRoute from './PrivateRoutes';

const Login = lazy(() => import('../Screens/Login/Login'))
const ForgotPassword = lazy(() => import('../Screens/ForgotPassword/ForgotPassword'));
const Main = lazy(() => import('../Screens/Main/Main'));
const UserPanel = lazy(() => import('../Screens/UserPanel/UserPanel'));
const ProfilePanel = lazy(() => import('../Screens/ProfilePanel/ProfilePanel'));
const KycPanel = lazy(() => import('../Screens/KycPanel/KycPanel'));
const FeePanel = lazy(() => import('../Screens/FeePanel/FeePanel'));
const AppRoutes = () => {
  return (
    <Suspense fallback={<CustomLoader tip={"Loading..."} />}>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route path={ROUTES.LOGIN} element={<Login />} />
          <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />


        </Route>

        <Route element={<PrivateRoute />}>
          <Route path={ROUTES.MAIN} element={<Main />}>
            <Route path={ROUTES.PROFILE_PANEL} element={<ProfilePanel />} />
            <Route path={ROUTES.KYC_PANEL} element={<KycPanel />} />
            <Route element={<UserPanel />} exact index />
            <Route path={ROUTES.FEE_PANEL} element={<FeePanel />} />

          </Route>

        </Route>


      </Routes>
    </Suspense>
  )
}

export default AppRoutes